<p-toast key="tst" [showTransformOptions]="'translateY(100%)'" [showTransitionOptions]="'1000ms'" [hideTransitionOptions]="'1000ms'" [showTransformOptions]="'translateX(100%)'" />

<p-confirmDialog />
<div *ngIf="loading | async" class="loading-container flex-content-center">
    <p-progressSpinner
        [style]="{ width: '50px', height: '50px' }"
        styleClass="custom-spinner"
        strokeWidth="8"
        fill="#EEEEEE"
        animationDuration=".5s"
    ></p-progressSpinner>
</div>
<router-outlet></router-outlet>
